























import { Component, Mixins } from 'vue-property-decorator'
import { View } from 'client-website-ts-library/plugins'
import TopBottomSection from '@/components/TopBottomSection.vue'
import NavigationLink from '@/components/Common/NavigationLink.vue'

@Component({
  components: {
    TopBottomSection,
    NavigationLink,
  },
})
export default class ReadyFinance extends Mixins(View) {
}
